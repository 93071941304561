* {
  font-family: 'Montserrat', sans-serif !important;
  text-rendering: optimizeLegibility;
  box-shadow: none !important;
}

.fa,
.far,
.fas {
  font-family: 'Montserrat', sans-serif;
}

.fab {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fbf9f6;
}

.tooltip-toggle {
  cursor: default;

  * {
    pointer-events: none;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}



.gradientText {
	font-family: 'Montserrat', sans-serif;
	// font-size: 6rem;
	font-weight:  bold;
	letter-spacing: 2px;

	text-align: center;
	color: #285aab;
	background-image: -webkit-linear-gradient(92deg, #1f4498, #285aab);
	background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: hue 8s infinite linear;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}
